@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap');

/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;


.swiper-container{
   margin-bottom: 30px;
   position: relative;
}

.swiper-container-horizontal>.swiper-pagination-bullets{
    bottom: 0;
    margin-top: 30px;
}